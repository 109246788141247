<template>
  <Modal
    :show="isShow"
    :title="title"
    :errors="errors"
    full
    @cansel="$emit('close')"
    @ok="handlerSubmit"
  >
    <div class="presetio-modals-modificated" ref="formContainer">
      <CRow>
        <CCol sm="6">
          <CInput v-model="form.name" label="Name" placeholder="Name" />
        </CCol>

        <CCol sm="3">
          <CInput
            v-model="form.hexStart"
            label="Hex Start"
            placeholder="Hex Start"
          />
        </CCol>

        <CCol sm="3">
          <CInput v-model="form.hexEnd" label="Hex End" placeholder="Hex End" />
        </CCol>
      </CRow>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";

import MixinsScreenModal from "@/mixins/screenModal";

export default {
  name: "NumberModalsModificated",

  mixins: [MixinsScreenModal],

  components: {},

  data: () => ({
    form: {
      name: null,
      hexStart: null,
      hexEnd: null,
    },
  }),

  computed: {
    title() {
      if (this.isShow) {
        return this.data ? "Edit Iconios tag" : "Add Iconios tag";
      }
    },
  },

  created() {
    for (let lang of this.languages) {
      this.form[`name_${lang}`] = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.presetio-modals-modificated {
  &__preset {
    padding: 10px;

    display: flex;
    flex-wrap: wrap;

    border: 1px solid #768192;
  }
}
</style>
